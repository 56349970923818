import styled from "styled-components"

const Section = styled.section`
  position: relative;
  width: 100%;
  min-height: 683px;
  height:100%;
  box-sizing: border-box;
  padding: 82px 80px;

  background: #ffffff;

  overflow: hidden;
`

export default Section
